import { UserData } from './userData';

export enum Status {
  idle = 'idle',
  requesting = 'requesting',
  requestingMore = 'requestingMore',
  error = 'error',
  success = 'success',
  change = 'change',
  postSuccess = 'postSuccess',
}

export interface UserDataState {
  status: Status,
  errorMsg: string | undefined,
  data?: UserData
}

export interface RootState {
  userData: UserDataState,
}
