import { SET_USER_DATA_REQUESTING, SET_USER_DATA_SUCCESS } from '../constants';
import { UserData } from '../types';

export const createGetUserDataRequestingAction = (githubUrl: string) => ({
  type: SET_USER_DATA_REQUESTING,
  payload: { githubUrl },
});

export const createGetUserDataSuccessAction = (userData: UserData) => ({
  type: SET_USER_DATA_SUCCESS,
  payload: { userData },
});
