import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './OverviewCard.module.scss';
import { Text } from '../Text';
import { getUserProfile } from '../../selectors/userDataSelector';
import { getCommitsCount } from '../../selectors';

export const OverviewCard: FunctionComponent = () => {
  const { t } = useTranslation();

  const profile = useSelector(getUserProfile);
  const commitCount = useSelector(getCommitsCount);
  const createdAt = profile ? new Date(profile.createdAt).getFullYear() : 0;
  const repositoryCount = profile ? profile.repos : 0;

  return (
    <div className={styles.container}>
      <Text type="bigWithLabel" label={t('Joined')} value={createdAt} />
      <Text type="bigWithLabel" label={t('Repositories')} value={repositoryCount} />
      <Text type="bigWithLabel" label={t('Age')} value="" />
      <Text type="bigWithLabel" label={t('Commits')} value={commitCount || 0} />
    </div>
  );
};
