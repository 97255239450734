import { AnyAction } from 'redux';
import { put, takeEvery } from 'redux-saga/effects';
import { createGetUserDataSuccessAction } from '../actions/userDataActions';
import { SET_USER_DATA_REQUESTING, API_URL } from '../constants';
import { UserData } from '../types';

function* getUserDataSaga(action: AnyAction) {
  try {
    const response: Response = yield fetch(`${API_URL}/api/crawler`, {
      body: `{"githubUrl": "${action.payload.githubUrl}"}`,
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': action.payload.githubUrl.Length,
        'Access-Control-Allow-Origin': '*',
      },
      method: 'POST',
    });

    const userData: UserData = yield response.json();
    yield put(createGetUserDataSuccessAction(userData));
  } catch (error) {
    console.log(error);
  }
}

export const githubCrawlerSaga = function* () {
  yield takeEvery(SET_USER_DATA_REQUESTING, getUserDataSaga);
};
