import React, { FunctionComponent } from 'react';
import styles from './Loader.module.scss';

export type LoaderProps = {
  height?: number,
  width?: number,
};

export const Loader: FunctionComponent<LoaderProps> = ({
  height = 40,
  width = 40,
}) => (<div className={styles.loader} style={{ height, width }} />);
