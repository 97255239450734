import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, styled } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { createGetUserDataRequestingAction } from '../../actions/userDataActions';
import { KeyboardKeyCodes, useKeyboadListener } from '../../hooks';
import styles from './SearchBar.module.scss';

const StyledTextField = styled(TextField)({
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInputBase-root': {
    borderBottomColor: 'white',
    color: 'white',
  },
  '& .MuiInputBase-root:hover': {
    borderBottomColor: 'white',
    color: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
  },
});

export const SearchBar: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const search = () => dispatch(createGetUserDataRequestingAction(searchText));
  useKeyboadListener(search, KeyboardKeyCodes.enter);

  return (
    <div className={styles.container}>
      <StyledTextField
        fullWidth
        required
        size="small"
        color="secondary"
        id="filled-secondary"
        label={t('searchProfile')}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                onClick={search}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </InputAdornment>
          ),
        }}
        placeholder={t('enterGithubProfileNameOrUrl')}
        onChange={(evt) => setSearchText(evt.target.value.trim())}
      />
    </div>
  );
};
