import React, { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

type BtnProps = {
  label: string,
  type: 'outlined' | 'contained'
};

const StyledOutlinedButton = withStyles({
  root: {
    borderRadius: 24,
    color: '#C9D1D9',
    borderColor: '#434B57',
    padding: '6px 30px',
    margin: '10px 0 20px 0',
    fontSize: 12,
    '&:hover': {
      borderColor: '#434B57',
      backgroundColor: '#624DFF',
    },
  },
})(Button);

const StyledContainedButton = withStyles({
  root: {
    backgroundColor: '#4D84FF',
    borderColor: '#4D84FF',
    borderRadius: '4px',
  },
})(StyledOutlinedButton);

export const Btn: FunctionComponent<BtnProps> = ({
  label,
  type,
}) => {
  if (type === 'contained') {
    return (
      <StyledContainedButton variant="contained">{label}</StyledContainedButton>
    );
  }

  return (
    <StyledOutlinedButton variant="outlined">{label}</StyledOutlinedButton>
  );
};
