import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './ProfilePanel.module.scss';
import { ProfileImage } from '../ProfileImage';
import { FollowPanel } from '../FollowPanel';
import { Text } from '../TextWithIcon';
import { Btn } from '../Btn';
import { getUserProfile } from '../../selectors/userDataSelector';
import { SearchBar } from '../SearchBar';

export const ProfilePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const profile = useSelector(getUserProfile);

  return (
    <div className={styles.container}>
      <SearchBar />
      <ProfileImage imageSrc={profile?.profileImageUrl} />
      <div>
        <h2>{profile?.name}</h2>
        <div className={styles.profileName}>
          @
          {profile?.login}
        </div>
      </div>
      <Btn label={t('openGithubProfile')} type="contained" />
      <div className={styles.bio}>{profile?.bio}</div>
      <FollowPanel
        followers={profile?.followers}
        following={profile?.following}
        stars={profile?.starredRepos}
      />
      <div className={styles.location}>
        <Text iconName="house" text={profile?.company} bold />
        <Text iconName="location" text={profile?.location} />
        <Text iconName="link" text={profile?.blog} link={profile?.blog} />
      </div>
      <div className={styles.emails}>
        {profile?.emails.map((email: any) => (
          <a key={email} href={`mailto:${email}`}>
            {email}
          </a>
        ))}
      </div>
    </div>
  );
};
