import React, { FunctionComponent, ReactNode } from 'react';
import styles from './FullscreenWrapper.module.scss';

export type FullscreenWrapperProps = {
  children: ReactNode,
};

export const FullscreenWrapper: FunctionComponent<FullscreenWrapperProps> = ({
  children,
}) => (<div className={styles.container}>{children}</div>);
