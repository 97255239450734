import React, { FunctionComponent } from 'react';
import styles from './Text.module.scss';
import { Icon, IconType } from '../Icon';

type TextProps = {
  iconName?: string;
  text?: string;
  bold?: boolean;
  link?: string;
};

export const Text: FunctionComponent<TextProps> = ({
  iconName,
  text,
  bold = false,
  link = undefined,
}) => (
  <div className={styles.container}>
    {iconName && <Icon size="16px" name={iconName} type={IconType.grey} />}
    {!link && <div className={bold ? styles.bold : ''}>{text}</div>}
    {link && <a className={bold ? styles.bold : ''} href={link}>{text}</a>}
  </div>
);
