import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FollowPanel.module.scss';
import { Text } from '../TextWithIcon';

type FollowPanelProps = {
  followers: number | undefined,
  following: number | undefined,
  stars: number | undefined
};

export const FollowPanel: FunctionComponent<FollowPanelProps> = ({ followers = '0', following = '0', stars = '0' }) => {
  const { t } = useTranslation();
  return (
    <ul className={styles.container}>
      <li>
        <Text iconName="users" text={followers.toString()} bold />
        &nbsp;
        {t('followers')}
      </li>
      <li>
        <Text text={following.toString()} bold />
        &nbsp;
        {t('following')}
      </li>
      <li>
        <Text iconName="star" text={stars.toString()} bold />
      </li>
    </ul>
  );
};
