import { RootState, Profile, Status } from '../types';

// export const getUserRoles = (state: RootState): Role[] | undefined => {
//     return state.userData.data?.roles;
// };

export const getUserProfile = (state: RootState): Profile | undefined => (
  state.userData.data?.profile
);

export const getUserProfileStatus = (state: RootState): Status => state.userData.status;
