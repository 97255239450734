import { useEffect } from 'react';

export enum KeyboardKeyCodes {
  enter = 13,
}

export const useKeyboadListener = (
  onClick: (e: KeyboardEvent) => void,
  targetKey?: KeyboardKeyCodes,
) => {
  useEffect(() => {
    const onClickHandler = (e: KeyboardEvent) => {
      if (targetKey && (targetKey as number) !== e.keyCode) {
        return;
      }

      onClick(e);
    };
    document.addEventListener('keydown', onClickHandler);

    return () => {
      document.removeEventListener('keydown', onClickHandler);
    };
  }, [onClick]);
};
