import React, { FunctionComponent } from 'react';
import styles from './Text.module.scss';

type TextProps = {
  type: 'big' | 'bigWithLabel' | 'inline',
  label?: string,
  value: string | number | number[],
};

export const Text: FunctionComponent<TextProps> = ({
  type,
  label,
  value,
}) => {
  if (type === 'big') {
    return (
      <div className={styles.value}>
        {Array.isArray(value)
          ? value[0].toString().concat(' ', '-', ' ', value[1].toString())
          : value}
      </div>
    );
  }
  if (type === 'bigWithLabel') {
    return (
      <div>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>
          {Array.isArray(value)
            ? value[0].toString().concat(' ', '-', ' ', value[1].toString())
            : value}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  );
};
