import React, { FunctionComponent } from 'react';
import {
  Bar, BarChart, ResponsiveContainer, XAxis,
} from 'recharts';
import styles from './BarGraph.module.scss';

export type BarGraphProps = {
  data: any,
  dataKey: string,
  height?: number | string,
  width?: number | string,
  xAxisDataKey?: string,
};

export const BarGraph: FunctionComponent<BarGraphProps> = ({
  data,
  dataKey,
  height = 100,
  width = 100,
  xAxisDataKey,
}) => (
  <ResponsiveContainer width={width} height={height}>
    <BarChart data={data}>
      {xAxisDataKey && <XAxis dataKey={xAxisDataKey} interval={0} tick={{ fontSize: 10 }} />}
      <Bar dataKey={dataKey} className={styles.bar} barSize={6} />
    </BarChart>
  </ResponsiveContainer>
);
