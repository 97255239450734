import React, { FunctionComponent } from 'react';
import styles from './ProfileImage.module.scss';
import { Icon, IconType } from '../Icon';

type ProfileImageProps = {
  imageSrc: string | undefined;
};

export const ProfileImage: FunctionComponent<ProfileImageProps> = ({
  imageSrc,
}) => (
  <div className={styles.container}>
    <img className={styles.image} src={imageSrc} alt="" />
    <div className={styles.sendButton}>
      <Icon name="paperplane" type={IconType.grey} size="24px" style={{ marginTop: 5 }} />
    </div>
  </div>
);
