import { produce } from 'immer';
import { UserDataState, Status } from '../types';
import { SET_USER_DATA_ERROR, SET_USER_DATA_REQUESTING, SET_USER_DATA_SUCCESS } from '../constants';

export const initUserDataState: UserDataState = {
  errorMsg: undefined,
  status: Status.idle,
  data: undefined,
};

export const userDataReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_USER_DATA_REQUESTING: {
      draft.status = Status.requesting;
      draft.errorMsg = undefined;
      break;
    }

    case SET_USER_DATA_SUCCESS: {
      draft.status = Status.success;
      draft.errorMsg = undefined;
      draft.data = action.payload.userData;
      break;
    }

    case SET_USER_DATA_ERROR: {
      draft.status = Status.error;
      draft.errorMsg = action.payload.errorMsg;
      break;
    }

    default: break;
  }
}, initUserDataState);
