import { CommitInfo, RootState } from '../types';

export const getCommitsCount = (state: RootState): number | undefined => (
  state.userData.data?.commitsCount
);

export const getCommitsMouthlyThisYear = (state: RootState): CommitInfo[] | undefined => (
  state.userData.data?.commitsMouthly
    ?.filter((x) => x.year === (new Date(Date.now()).getFullYear()))
);
