import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './HomePage.module.scss';
import { ProfilePanel } from '../../components/ProfilePanel';
import { Card } from '../../components/Card';
import { OverviewCard } from '../../components/OverviewCard';
import { getCommitsMouthlyThisYear, getTopFiveLanguages, getUserProfileStatus } from '../../selectors';
import { Status } from '../../types';
import { Loader } from '../../components/Loader';
import { FullscreenWrapper } from '../../components/FullscreenWrapper';
import { BarGraph } from '../../components/BarChart';
import { Text } from '../../components/Text';

export const HomePage: FunctionComponent = () => {
  const { t } = useTranslation();

  const userProfileStatus = useSelector(getUserProfileStatus);
  const topFiveLanguages = useSelector(getTopFiveLanguages);
  const commitsMouthlyThisYear = useSelector(getCommitsMouthlyThisYear);

  return (
    <div className={styles.container}>
      {
                userProfileStatus === Status.requesting
                && (
                <FullscreenWrapper>
                  <Loader height={90} width={90} />
                </FullscreenWrapper>
                )
            }
      <div className={styles.profilePanel}>
        <ProfilePanel />
      </div>
      <div className={styles.content}>
        <Card title={t('overview')} minWidth={240}>
          <OverviewCard />
        </Card>
        <Card title={t('role')} />
        <Card title={t('stackTop5')} minWidth={300}>
          <BarGraph data={topFiveLanguages} dataKey="percent" xAxisDataKey="name" width="95%" height={180} />
        </Card>
        <Card title={t('commitsMonthly')}>
          <Text type="big" value={168} />
          <BarGraph dataKey="commits" data={commitsMouthlyThisYear} width="95%" height={180} />
        </Card>
        <Card title={t('hoursActivity')} />
        <Card title={t('diplomaThesis')} />
        <Card title={t('socialNetworks')} />
      </div>
    </div>
  );
};
