import React, { FunctionComponent, MouseEventHandler } from 'react';

export enum IconType {
  default = '',
  grey = 'Grey',
  white = 'White',
  light = 'Light',
  red = 'Red',
  green = 'Green',
  blue = 'Blue',
  black = 'Black',
}

type IconProps = {
  name: string;
  type: IconType;
  size?: string;
  rotation?: string;
  style?: object;
  onClick?: MouseEventHandler;
};

export const Icon: FunctionComponent<IconProps> = ({
  name,
  size,
  rotation,
  type,
  style,
  onClick,
}) => (
  <img
    onClick={onClick}
    role="presentation"
    src={`${process.env.PUBLIC_URL}/icons/${name}${type}.svg`}
    alt={name}
    width={size}
    style={{ ...style, transform: `rotate(${rotation})` }}
  />
);
