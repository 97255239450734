import React, { FunctionComponent, ReactNode } from 'react';
import styles from './card.module.scss';
import { Btn } from '../Btn';

type CardProps = {
  title: string,
  subtitle?: string,
  children?: ReactNode,
  buttonLabel?: string,
  minWidth?: number,
};

export const Card: FunctionComponent<CardProps> = ({
  title,
  subtitle,
  children,
  buttonLabel,
  minWidth,
}) => (
  <div className={styles.container} style={{ minWidth }}>
    <div className={styles.headerWrapper}>
      <div>
        <div className={styles.header}>{title}</div>
        {subtitle && <div className={styles.subheader}>{subtitle}</div>}
      </div>
      {buttonLabel && <Btn label={buttonLabel} type="outlined" />}
    </div>
    <div className={styles.content}>
      {children}
    </div>
  </div>
);
